import React, {useState} from 'react'
import SideBar from '../SideBar'
import NavBar from '../NavBar';
import Footer from '../Footer'
import ProductTitle from '../ProductCategory/ProductTitle';
import { homeObjU, homeObjV } from '../ProductCategory/ProductTitle/data';
import Slider from '../Carousel';
import { homeObjA } from '../Carousel/data';
import InfoSection from '../AboutSectionA';
import {  SectionAinfo , SectionAinfoB} from '../AboutSectionA/data';
import AboutSectionB from '../AboutSectionB';
import { SectionInfoB } from '../AboutSectionB/data';
import Reviews from '../Review';
import SubTitle from '../Review/title';
import { ReviewsInfo } from '../Review/data';
import Awards from '../Awards';
import { AwardsInfo , MemberOrgarnizations} from '../Awards/data';


const AboutZicofe = () => {
    const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
      setIsOpen(!isOpen)
  };
  return (
    <div>
    <SideBar  isOpen={isOpen} toggle={toggle}/>
        <NavBar toggle={toggle}/>
        {/* <ProductHeading {...homeObjK}/> */}
        <ProductTitle  {...homeObjU}/>
        <Slider {...homeObjA}/>
        <InfoSection {...SectionAinfo}/>
        <Awards {...AwardsInfo}/>
        <AboutSectionB  {...SectionInfoB}/>
        {/* <Awards {...MemberOrgarnizations}/> */}
        <InfoSection {...SectionAinfoB}/>
        <SubTitle  {...ReviewsInfo}/>
        <Reviews/>
        
        <Footer/>

    </div>
  )
}

export default AboutZicofe