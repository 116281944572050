import styled from 'styled-components';

export const AwardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
    

    .ant-card {
        margin-left:32px;
    }

  
     
    .ant-col-6 {

                display: block;
                flex: 0 0 0;
                max-width: 50%;

     }

     @media screen and (max-width: 960px) {
        .ant-col-6 {
            display: block;
            flex: 0 0 0;
            max-width: 100%;     
     }

     .ant-col-8 {
    display: block;
    flex: 0 0 30.33333333%;
    max-width: 90%;
}

     }
        
    `;