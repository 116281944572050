import React from 'react'
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const Shop = () => {
    return (
        <div className='homeWrapper'>
        <NavBar/>
        <h1>shop </h1>
        <Footer/>
        </div>
    )
}

export default Shop
