import React from 'react';
import { GridWrapper} from './reviewElements';
import {Typography } from "antd";
//import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css' 
import { Comment, Tooltip, List ,Avatar, Form, Button, Input} from 'antd';
import moment from 'moment';

const data = [
    {
      actions: [<span key="comment-list-reply-to-0">Reply to</span>],
      author: 'Rubanza Silva',
      avatar: 'https://joeschmoe.io/api/v1/random',
      content: (
        <p>
          Evaluated as espresso. Bold, sweetly roast-toned, chocolaty. Dark chocolate, rhododendron, raisin, scorched oak, molasses in aroma and small cup. 
          Velvety mouthfeel; gently drying, roasty-sweet finish with dark chocolate and scorched oak undertones.
           The milk shot is both floral and chocolaty, supported by notes of raisin and molasses..
        </p>
      ),
      datetime: (
        <Tooltip title={moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')}>
          <span>{moment().subtract(1, 'days').fromNow()}</span>
        </Tooltip>
      ),
    },


    {
      actions: [<span key="comment-list-reply-to-0">Reply to</span>],
      author: 'Ssukwe Wilson',
      avatar: 'https://joeschmoe.io/api/v1/random',
      content: (
        <p>
          A ready-to-drink black coffee, tested cold. Date, almond, caramel, freesia, bergamot. 
          Sweet-toned with gentle, round acidity; velvety-smooth mouthfeel. Richly sweet, gentle finish. 
          Combined with milk, this cold brew is caramelly and nut-toned with a hint of spice.
        </p>
      ),
      datetime: (
        <Tooltip title={moment().subtract(2, 'days').format('YYYY-MM-DD HH:mm:ss')}>
          <span>{moment().subtract(2, 'days').fromNow()}</span>
        </Tooltip>
      ),
    },
  ];

const { Title , Text } = Typography;


const Reviews = () => {
    

    return (
        <GridWrapper >
        <List
    className="comment-list"
    header={`${data.length} comments`}
    itemLayout="horizontal"
    dataSource={data}
    renderItem={item => (
      <li>
        <Comment
          actions={item.actions}
          author={item.author}
          avatar={item.avatar}
          content={item.content}
          datetime={item.datetime}
        />
      </li>
    )}
  />,        
        </GridWrapper>

    
    )
}

export default Reviews;
