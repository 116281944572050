import { BrowserRouter as Router,Routes, Route} from 'react-router-dom';

export const homeObjY = {
    id:'about',
    price:'90 cts/lb',
    img:require('../../Images/heroImage.png'),
};

export const SliderData = [
    {
        title:'Supporting small scale holder farmers',
        caption:'We partner and assist small holder farmers with various pre-harvest inputs',
        buttonText:'Learn More',
        path:'ContactUs',
        image:'https://d322abd807wvow.cloudfront.net/zicofe-photos/smallScaleFarmersT.jpg',
        alt:'Supporting farmers',
        
    },
    {
        title:'Promoting Women In Coffee',
        caption:'We are a proud member of the International Women In Coffee Alliance',
        buttonText:'Learn More',
        path:'/contactUs',
        image:'https://d322abd807wvow.cloudfront.net/zicofe-photos/686A3898xyz.jpg',
        alt:'Women In Coffee',
        
    },
    {
        title:'Coffee Sourcing', 
        caption:'We work with more than 35 farmer groups to sustainably source coffee',
        buttonText:'Learn more',
        path:'contactUs',
        image:'https://d322abd807wvow.cloudfront.net/zicofe-photos/growersB.jpg',
        alt:'Coffee Growers',

    },
    
    {
        title:'Coffee Processors',
        caption:'With our new plant, we can process more than 26,000 tonnes per year',
        buttonText:'Learn More',
        path:"contactUs",
        image:'https://d322abd807wvow.cloudfront.net/zicofe-photos/factoryOptimized.jpg',
        alt:'Coffee Processors',
        
    },
    {
        title:'Coffee Roasters',
        caption:'We have refined our techniques over generations',
        buttonText:'Experience  Ugandan Coffee',
        path:"/contactUs",
        image:'https://d322abd807wvow.cloudfront.net/zicofe-photos/ZICOFE52Optimized.jpg',
        alt:'Coffee Roasters',
        
    },
    {
        title:'ZICOFE Cafe',
        caption:'Try our award winning coffee at our coffeeshop in Ntinda',
        buttonText:'Visit our Cafe',
        path:"/ContactUs",
        image:'https://d322abd807wvow.cloudfront.net/zicofe-photos/cafeOptimized.jpg',
        alt:'Coffee Roasters',
        
    },
    {
        title:'Coffee Exporters',
        caption:'We export coffee to all parts of the world',
        buttonText:'Learn More',
        path:'/contactUs',
        image:'https://d322abd807wvow.cloudfront.net/zicofe-photos/export15Optimized.jpg',
        alt:'Coffee Exporters',
        
    }
];
