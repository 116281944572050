export const homeObjZ = {
    id:'about',
    title:'Farming Projects',
    headline:'ZICOFE offers farmers especially women & youth training on various activities in the coffee value chain in the form of better farm practices, financial literacy, health, agro-input knowledge techniques. During this training, we teach farmers sustainable production practices, how to increase their yields, when to harvest, and post-harvest handling',
    imgStart:false,
    img:require('../../Images/communityIconB.jpg'),
    alt:'Car',
};

//data for about zicofe section
export const homeObjX = {
    id:'aboutZicofe',
    title:'Women In Coffee',
    headline:
    'We are committed to changing the world through our most loved drink, coffee and serving you. A percentage of our after tax proﬁts support various coffee organizations and projects that advocate for young girls and women in Uganda.we try our best to empower, advance and connect women in the coffee value chain.',
    imgStart:false,
    img:require('../../Images/communityIcon.jpg'),
    alt:'Car',
};

export const homeObjY = {
    id:'aboutZicofe',
    title:'Women In Coffee',
    headline:
    'We are committed to changing the world through our most loved drink, coffee and serving you. A percentage of our after tax proﬁts support various coffee organizations and projects that advocate for young girls and women in Uganda.we try our best to empower, advance and connect women in the coffee value chain.',
    imgStart:false,
    img:require('../../Images/communityIcon.jpg'),
    alt:'Car',
};