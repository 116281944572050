import React, {useState} from 'react';
import ContactZicofe from '../components/ContactUs';
import { homeObjA } from '../components/ContactUs/data';
import { homeObjW} from '../components/ContactTitle/data';
import ContactTitleA from '../components/ContactTitle';
import SideBar from '../components/SideBar';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const ContactUs = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
      setIsOpen(!isOpen)
  };
  return <div id='contactContainer'>
  <SideBar  isOpen={isOpen} toggle={toggle}/>
  <NavBar toggle={toggle}/>
  <ContactTitleA {...homeObjW} />
  <ContactZicofe {...homeObjA}/>
  <Footer/>
      
  </div>;
};

export default ContactUs;
