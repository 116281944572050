//data for about awards section
export const AwardsInfo = {
    id:'about',
    title:'Award Winning Coffee',
    img:require('../../Images/robustaAward.png'),
    img2:require('../../Images/oikoAward.png'),
    img3:require('../../Images/ucdaAward.png'),
    img4:'',
    alt:'About ZICOFE',
};

//data for about member orgarnizations section
export const MemberOrgarnizations = {
    id:'about',
    title:'We are proud members of',
    img:require('../../Images/UCF-LOGO-small.png'),
    alt:'About ZICOFE',
};