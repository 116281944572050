import React from 'react'
import AboutZicofe from '../components/AboutZicofe'
import Footer from '../components/Footer'
import HeroCategory from '../components/Hero'
import NavBar from '../components/NavBar'

const About = () => {
    return (
        <div className='homeWrapper'>
        <AboutZicofe />
        </div>
    )
}

export default About
