import styled from 'styled-components';

 export const MarqueeWrapper = styled.div`
  width: 100%;
  color: #008000;
  margin-top: 15px;
  overflow: hidden;
  padding: 15px 30px;
  background-color: #000;
  &:first-child {
    margin-top: 0;
    }
`

export const MarqueeText = styled.div`
    margin: 0 100px;
    font-size: 1.75em;
    white-space: nowrap;
    `