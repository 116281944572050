import  styled from 'styled-components';

export const GalleryContainer = styled.div`
 margin:64px;
 padding:16px;

 @media screen and (max-width:720px) {
     margin:24px;

 }
`

export const GalleryItem = styled.div`
background:  #f7f2ef;
padding: 48px 0 24px 0;
margin:32px 8px 0px 8px;

`

export const GalleryTitle = styled.div`
margin:auto;
 line-height:75px;
 display:flex;
 flex-direction:column;
 text-align:center;
 align-content:center;
 ${'' /* padding-top:48px; */}
/* From https://css.glass */
background:  #f7f2ef;
${'' /* background: rgba(255, 255, 255, 0.2); */}
border-radius: 16px;
box-shadow: 0 4px 30px  #f7f2ef;
${'' /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */}
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid  #f7f2ef;
${'' /* border: 1px solid rgba(255, 255, 255, 0.3); */}
`