//data for about zicofe section
export const SectionInfoB = {
    id:'about',
    title:'We use coffee roasting techniques',
    headline:'refined and crafted over generations to create a unique flavor profile that is unique to each coffee bean. ',
    text:' We further invested in a 50kg-per-batch and a 25kg-per-batch roastery that roasts the Zicofe blend medium roast ',
    text1:'We also offer toll roasting services to coffee farmers, buyers and enthusiasts',
    imgStart:false,
    img:require('../../Images/roasteryA.jpg'),
    alt:'Car',
};

export const homeObjC = {
    id:'about',
    title:'Women In Coffee',
    headline:
    'We are committed to changing the world through our most loved drink, coffee and serving you. A percentage of our after tax proﬁts support various coffee organizations and projects that advocate for young girls and women in Uganda.we try our best to empower, advance and connect women in the coffee value chain.',
    imgStart:false,
    img:require('../../Images/communityIcon.jpg'),
    alt:'Car',
};


