//PRICES FOR GREEN BEANS ROBUSTA ON GREEN BEANS PAGE
export const homeObjA = {
    id:'ContactTitle',
    title: 'Screen 1200',
    title1:'Screen 1500',
    title2:'Screen 1800',
    description: 'Mild and smooth',
    description1: 'Pleasant tobacco and Cedar notes',
    description2: 'Sweet with fruity notes',
    img:require('../../../Images/greenBeans.jpg'),
};

export const homeObjB = {
    id:'ContactTitle',
    title: 'Grade A',
    title1:'Grade AA',
    title2:'Clean',
    description: 'Mild and smooth',
    description1: 'Pleasant tobacco and Cedar notes',
    description2: 'Pleasant aroma',
    img:require('../../../Images/greenBeans.jpg'),
};


export const homeObjC = {
    id:'ContactTitle',
    headline:'Washed Arabica',
    title1: 'Grade AA',
    title2:'Grade AB',
    description1: 'Mild and smooth',
    description2: 'Pleasant tobacco and Cedar notes',
    img:require('../../../Images/greenBeans.jpg'),
};


export const homeObjD = {
    id:'ProductTitle',
    title: 'Medium roast',
    title1:'Dark roast',
    title2:'Light roast',
    description: 'Mild and smooth',
    description1: 'Pleasant tobacco and Cedar notes',
    description2: 'Sweet with fruity notes',
    img:require('../../../Images/icon1.jpg'),
};


export const homeObjE = {
    id:'ProductTitle',
    title: 'Grade A',
    title1:'Grade AA',
    title2:'Clean',
    description: 'Mild and smooth',
    description1: 'Pleasant tobacco and Cedar notes',
    description2: 'Sweet with fruity notes',
    img:require('../../../Images/icon1.jpg'),
};


export const homeObjF = {
    id:'ContactTitle',
    headline:'Washed Arabica',
    title1: 'Grade AA',
    title2:'Grade AB',
    description1: 'Mild and smooth',
    description2: 'Pleasant tobacco and Cedar notes',
    img:require('../../../Images/icon1.jpg'),
};

