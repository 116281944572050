import React from 'react';
import ProductHeading from '../ProductCategory/ProductHeading';
import ProductTitle from '../ProductCategory/ProductTitle';
import ProductCard from '../ProductCategory/ProductCard';
import {homeObjZ} from '../ProductCategory/ProductHeading/data';
import {homeObjA,homeObjB, homeObjC}  from '../ProductCategory//ProductCard/data';
import {homeObjX, homeObjY,} from '../ProductCategory//ProductTitle/data';
import ArabicaCard from '../ProductCategory/ProductCard/ArabicaWashed';
import ProductSubTitle from '../ProductCategory/ProductSubTitle';
import { homeObjF } from '../ProductCategory/ProductSubTitle/data';
import { Divider } from 'antd';

const GreenBeansCategory = () => {
  return <div>
      <ProductHeading {...homeObjZ}/>
      <ProductTitle {...homeObjX}/>
      <ProductCard {...homeObjA}/>
      <ProductTitle {...homeObjY}/>
      <ProductSubTitle {...homeObjF}/>
      <ArabicaCard {...homeObjC}/>
      <Divider/>
  </div>;
};

export default GreenBeansCategory;
