import React,{useState} from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import SideBar from '../components/SideBar'
import GreenBeansCategory from '../components/GreenBeans'

  

const GreenBeans = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };
    return (
        <div className='homeWrapper'>
        <SideBar  isOpen={isOpen} toggle={toggle}/>
        <NavBar toggle={toggle}/>
        {/* <ProductCategory/> */}
        <GreenBeansCategory/>
        <Footer/>

        </div>
    )
}

export default GreenBeans
