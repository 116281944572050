import styled from "styled-components";

export const GridWrapper = styled.div`
 display:flex;
 width:800px;
 height:500px;

 @media screen and (max-width: 768px) {
    width:70%;
    height:70%;
    margin:56px;
 }
`

export const ProductCategory = styled.div`
    margin-top:10px;
    display:flex;
    justify-content:center;
    align-items:center;
    ${'' /* position:sticky; */}
    top:0;
    background-color: #fff;
    cursor:pointer;
    font-family:"Lato","Roboto","Helvetica Neue",sans-serif;
    `
export const ProductName = styled.div`
    justify-content:center;
    align-items:center;
    display:flex;
    flex-direction:column;
    width:100%;
    margin-top:72px;
    margin-bottom:48px;

    @media screen and (max-width: 768px) {
        display:flex;
        justify-content:center;
        align-items:center;
    align-text:center;
    align-content:center;
    margin-top:38px;
    padding:16px;
        
    }
    `

// export const GridWrapper = styled.ul`
//  display:grid;
//  grid-template-columns:1fr;
//  list-style-type:none;
//  margin:56px;
//  width:60%;
//  align-content:center;
 
// @media screen and (max-width:960px) {
//     grid-template-columns:1fr;
//     align-items:Center;
//     text-align:Center;
//     margin:16px;
// }
// `


// export const GridItem = styled.li`
// background-color:#F7F2EF;
// text-align:center;

// & .InfoSectionTextWrap{

//     @media screen and (max-width:960px) {
//     }
// }

// & .InfoSectionImageWrap{
//     @media screen and (max-width:960px) {
//     }
// }



// `

// export const SectionText = styled.div`
//     margin:auto;
//  line-height:75px;
//  display:flex;
//  flex-direction:column;
//  text-align:center;
//  align-content:center;
//  padding:64px;


//  @media screen and (max-width:780px) {

//  }

// `

// //column 2
// export const ImgWrap = styled.div`
//     margin:auto;
//  display:flex; 
//  flex-direction:row;
//  flex-wrap:wrap;
//  justify-content:space-around;
//  align-items:center;
//  text-align:center;


//  @media screen and (max-width: 968px) {
//     }
// `

// //removing the width causes element to go wild
// export const Img = styled.img`
//     margin:auto;
//    width:100%;
//    height:600px;
//    object-fit:contain;
//    left:0;
//  display:flex; 
//  flex-wrap:wrap;

 
//  @media screen and (max-width:720px) {
//      flex-direction:column;
//      align-items:center;
//      margin-top:-180px;

//  }
// `

// export const BtnWrap = styled.div`
//     margin:auto;
//     display:flex;
//     flex-direction:row;
//     flex-wrap:wrap;
//     justify-content:center;
//     align-items:center;
//     text-align:center;
    
//     @media screen and (max-width: 768px) {
//         flex-basis:100%;
//     }
// `