export const homeObjW = {
    id:'ContactTitle',
    title:'Contacting Zicofe',
};

export const homeObjZ = {
    id:'ContactTitle',
    title:'C0-OPORATE ADDRESS',
};


