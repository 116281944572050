import styled from "styled-components";

// export const GridContainer = styled.div`
//  height:800px;
//  width:1200px;
//  display:grid;
// `
export const GridWrapper = styled.ul`
 display:grid;
 grid-template-columns:1fr 1fr;
 background-color:#fff;
 list-style-type:none;
 height:900px;
 grid-gap:16px;
 
 
 

@media screen and (max-width:960px) {
    grid-template-columns:1fr;
    align-items:Center;
    text-align:Center;
    height:100%;
}
`


export const GridItem = styled.li`
background-color:#fff;
height:100%;
text-align:center;

& .gridOne{
    background-color:blue;
}

`

export const SectionText = styled.div`
    margin:auto;
 line-height:75px;
 display:flex;
 flex-direction:column;
 text-align:center;
 align-content:center;
 padding:100px;
 border:1px solid #000;
 background-color:#fff;
 margin:4px;


 @media screen and (max-width:780px) {
     margin:16px 0 16px 0;
     padding:32px;
 }

`

export const SectionTextB = styled.div`
    margin:auto;
 line-height:75px;
 display:flex;
 flex-direction:column;
 text-align:center;
 align-content:center;
 padding:100px;
 margin:4px;
 border:1px solid #000;
 ${'' /* background-color:#F3E1DD; */}


 @media screen and (max-width:780px) {
     margin:16px 0 16px 0;
     padding:32px;
 }

`


//column 2
export const ImgWrap = styled.div`
    margin:auto;
 display:flex; 
 flex-direction:row;
 flex-wrap:wrap;
 justify-content:space-around;
 align-items:center;
 text-align:center;
 margin-top:24px;

 @media screen and (max-width: 968px) {
        flex-basis:100%;
        padding:0px;
        margin-bottom:64px;
    }
`

//removing the width causes element to go wild
export const Img = styled.img`
    margin:auto;
   width:100%;
   height:400px;
   object-fit:contain;
   left:0;
   
 display:flex; 
 flex-wrap:wrap;
 
 @media screen and (max-width:720px) {
     flex-direction:column;
     align-items:center;

 }
`

export const BtnWrap = styled.div`
    margin:auto;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:center;
    align-items:center;
    text-align:center;

    @media screen and (max-width: 768px) {
        flex-basis:100%;

    }
`