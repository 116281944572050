import styled from 'styled-components';
import {Link as LinkR} from 'react-router-dom';
//import {NavLink as LinkNav} from 'react-router-dom';

export const Nav = styled.nav`
 height: 120px;
 padding-top:0.2rem;
 ${'' /*  padding-top:1.125rem;*/}
 ${'' /* padding-bottom:48px; */}
 ${'' /* margin-top:10px; */}
 display:flex;
 justify-content:center;
 align-items:center;
 position:sticky;
 ${'' /* top:0; */}
 border:border-box;
 background-color: #FFFFFF;
 ${'' /*  background-color: #F7F2EF; */}
 cursor:pointer;
 font-family:"Lato","Roboto","Helvetica Neue",sans-serif;

 @media screen and (max-width:960px) {
     transition: 0.8s all ease;
 }
`

export const NavBarContainer = styled.div`
 display:flex;
 justify-content:space-between;
 height:100px;
 width:88%;
 padding: 0;
 max-width:2400px;

 @media screen and (max-width:960px) {
     width:80%;
        
    }


`


export const NavLogo = styled(LinkR)`
 color:green;
 justify-self:left;
 cursor:pointer;
 display:flex;
 align-items:left;
 
 ${'' /* margin-right:16px; */}


 @media screen and (max-width:960px) {
        transition: 0.8s all ease;
        justify-content:center;
        align-items:center;
        align-text:center;
        ${'' /* padding-top:16px; */}
        margin-right:34%;  
    }
`


export const MobileIcon = styled.div`
 display:none;

 @media screen and (max-width:780px) {
     display:block;
     position:absolute;
     top:0;
     right:0;
     transform: translate(-100%,60%);
     font-size:1.8rem;
     cursor:pointer;
     color:#35241A;
 }
`

export const NavMenu = styled.ul`
 display:flex;
 align-items:center;
 list-style:none;
 text-align:center;
 font-family:"Lato","Roboto","Helvetica Neue",sans-serif;
 margin:auto;

 @media screen and (max-width:760px) {
     display:none;
 }
`

export const NavItem = styled.li`
 font-family:"Lato","Roboto","Helvetica Neue",sans-serif;
`

//to change the color of the links in the navbar
export const NavLinks = styled(LinkR)`
 color:#35241A;
 display:flex;
 align-items:center;
 text-decoration:none;
 padding:0 1.5rem;
 font-weight:400;
 cursor:pointer;
 font-family:"Lato","Roboto","Helvetica Neue",sans-serif;
 font-size:15px;




 &:visited {
    color:blurywood;
}

&:hover {
    color:#2c8746;
    transition: all 0.2s ease-in-out;
    border-bottom: 2px solid #2c8746;
    
}

&:active {
    border-bottom: 2px solid blue;
    color:blue;
    border-bottom: 2px solid blue;
}

@media screen and (max-width:960px) {
    padding:0 12px ;
}




`

export const NavBtn = styled.nav`
 display:flex;
 align-items:center;


 @media screen and (max-width:768px) {
     display:none;
 }
`

export const NavBtnLink = styled(LinkR)`
 color:#2c8746;
 display: flex;
 text-decoration:none;
 font-weight:400;
 cursor:pointer;
 font-family:"Lato","Roboto","Helvetica Neue",sans-serif;
 font-size:15px;



 &:hover {
     color:#35241A;
     transition: all 0.2s ease-in-out;
     border-bottom: 2px solid #35241A;
 }
`
export const Img = styled.img`  
  
    object-fit: cover;
`