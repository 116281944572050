export const homeObjV = {
    id:'ContactTitle',
    title:'Roasted Coffee Beans',
};

export const homeObjW = {
    id:'ContactTitle',
    title:'Green Coffee Beans',
};

export const homeObjZ = {
    id:'ContactTitle',
    title:'Green Coffee Beans',
};

//data for about zicofe section
export const homeObjK = {
    id:'AboutZicofeTitle',
    title:'ABOUT ZIGOTI COFFEE',
};





