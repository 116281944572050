import React from 'react';
import {Typography} from "antd";
//import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css' 
import {ProductCategory,ProductName} from "./ProductTitleElements";

const { Title , Text } = Typography;

const ProductTitle = ({title,text1}) => {
  return <ProductCategory id='productCategory'>
      <ProductName id='productName'> 
      <Title level={3} style={{fontSize:"1.75em",lineHeight:"1.10722",fontWeight:"400",letterSpacing:"-.005em",marginTop:"6px",fontFamily:"Lato"}}> 
      {title} 
      </Title>

      <Text level={5} style={{color:"#35241A", fontSize:"17px",lineHeight:"1.2381",fontWeight:"200",letterSpacing:".016em",marginTop:"15px",marginBottom:"72px",fontFamily:"Roboto"}}>{text1}</Text>
  </ProductName>
  </ProductCategory>;
};

export default ProductTitle;
