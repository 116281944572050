export const homeObjA = {
    id:'about',
    title:'Women In Coffee',
    headline:
    'ZICOFE is  a member of International Women in Coffee Alliance. (IWCA Uganda  Chapter), an orgarnization with the sole purpose of advocating for gender equality and women’s rights.',
    headline1:'This partnership has enabled ZICOFE to coordinate and execute gender based programs in the farming communities especially at household level with training and sensitization on household financial decision making while including women, farm managment, primary health,providing access to our processing and roasting facilities,micro-lots for export, value addition and financial literacy.',
    headline2:'Through these activities, we hope to make a difference by availing opportunities for the womens involvement in the coffee value chain.',
    imgStart:false,
    img:require('../../Images/womenInCoffee.jpg'),
    alt:'Car',
};


//data for about zicofe section
export const homeObjB = {
    id:'about',
    title:'38 Years Ago, We started',
    headline:
    'farming and processing coffee.',
    imgStart:false,
    img:require('../../Images/communityIcon.jpg'),
    alt:'Car',
};

export const homeObjC = {
    id:'about',
    title:'Women In Coffee',
    headline:
    'We are committed to changing the world through our most loved drink, coffee and serving you. A percentage of our after tax proﬁts support various coffee organizations and projects that advocate for young girls and women in Uganda.we try our best to empower, advance and connect women in the coffee value chain.',
    imgStart:false,
    img:require('../../Images/womenInCoffee.jpg'),
    alt:'Car',
};


