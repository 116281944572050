import React from 'react'
import {Carousel} from "antd";
//import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css' 
import { CarouselContainer} from './CarouselElements';

const contentStyle = {
    height: '800px',
    width:'100%',
    color: '#fff',
    lineHeight: '800px',
    textAlign: 'center',
    background: '#F7F2EF',
    

  };

const Slider = ({picA,picB,picC,picD,title}) => {
  return (
    <CarouselContainer>
        <Carousel autoplay>
            <div><h3 style={contentStyle}>  <img src={picA}/>  </h3></div>
            <div><h3 style={contentStyle}> <img src={picB}/>   </h3></div>
            <div><h3 style={contentStyle}> <img src={picC}/>   </h3></div>
            <div><h3 style={contentStyle}> <img src={picD}/>   </h3></div>
        </Carousel>
        
    </CarouselContainer>
  )
}

export default Slider