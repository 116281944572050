import React from 'react';
import {Typography} from "antd";
//import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css' 
import {ProductCategory,ProductName} from "./ProductSubTitleElements";

const { Title , Text } = Typography;

const ProductSubTitle = ({title,text1}) => {
  return <ProductCategory id='productCategory'>
      <ProductName id='productName'> 
      

      <Text level={5} style={{color:"#35241A", fontSize:"21px",lineHeight:"1.381",fontWeight:"400",letterSpacing:".011em",marginTop:"0.65em",fontFamily:"Roboto"}}>{title}</Text>
  </ProductName>
  </ProductCategory>;
};

export default ProductSubTitle;
