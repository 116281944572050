//about zicofe section data
export const homeObjA = {
    id:'about',
    title:'Women In Coffee',
    headline:
    'We are committed to changing the world through our most loved drink, coffee and serving you. A percentage of our after tax proﬁts support various coffee organizations and projects that advocate for young girls and women in Uganda.we try our best to empower, advance and connect women in the coffee value chain.',
    imgStart:false,
    picA:'https://d322abd807wvow.cloudfront.net/zicofe-photos/zicofe-products-1.jpg',
    picB:'https://d322abd807wvow.cloudfront.net/zicofe-photos/zicofecafe.jpeg',
    picC:'https://d322abd807wvow.cloudfront.net/zicofe-photos/zicofeFactory.jpeg',
    picD:'https://d322abd807wvow.cloudfront.net/zicofe-photos/expo1.jpeg',
    alt:'Car',
};