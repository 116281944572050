import React,{useState} from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import SideBar from '../components/SideBar'
import RoastedBeans from '../components/RoastedBeans'

const RoastedCoffee = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };
    return (
        <div className='homeWrapper'>
        <SideBar  isOpen={isOpen} toggle={toggle}/>
        <NavBar toggle={toggle}/>
        <RoastedBeans/>
        <Footer/>

        </div>
        
    )
}

export default RoastedCoffee
