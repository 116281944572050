export const homeObjX = {
    id:'ContactTitle',
    title:'Robusta Natural Coffee',
    text1:'We offer natural robusta coffee in various grades '
};

export const homeObjY = {
    id:'ContactTitle',
    title:'Arabica Coffee ',
    text1:'We offer arabica coffee in various grades and types such as washed, natural and honey'
};


export const homeObjZ = {
    id:'ProductTitle',
    title:'Roasted blends',
    text1:'We offer various roasted blends of coffee '
};


export const homeObjR = {
    id:'ProductTitle',
    title:'Washed Arabica Coffee ',
    text1:'We offer roasted washed arabica coffee in various grades and types such as washed and natural '
};

export const homeObjS = {
    id:'ProductTitle',
    title:'Washed Arabica Coffee ',
};

export const homeObjT = {
    id:'ProductTitle',
    title:'Washed Arabica Coffee ',
};


//data for about zicofe section
export const homeObjU = {
    id:'ProductTitle',
    title:'Our Story',
    text1:'This is the story of ugandan coffee right from the farmer to the cup starting in 1984 ',
};


//comment section  title data
export const homeObjV = {
    id:'ProductTitle',
    title:'Reviews',
    
};