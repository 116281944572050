import styled from 'styled-components';

export const ProductCategory = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    top:0;
    background-color: #F7F2EF;
    cursor:pointer;
    font-family:"Lato","Roboto","Helvetica Neue",sans-serif;
    `
export const ProductName = styled.div`
    justify-content:center;
    align-items:center;
    display:flex;
    flex-direction:column;
    width:100%;
    margin:30px;
    `
