import styled from 'styled-components';

export const ProductCategory = styled.div`
    margin-top:10px;
    display:flex;
    justify-content:center;
    align-items:center;
    ${'' /* position:sticky; */}
    top:0;
    background-color: #F7F2EF;
    cursor:pointer;
    font-family:"Lato","Roboto","Helvetica Neue",sans-serif;
    `
export const ProductName = styled.div`
    justify-content:center;
    align-items:center;
    display:flex;
    flex-direction:column;
    width:100%;
    margin-top:72px;

    @media screen and (max-width: 768px) {
        display:flex;
        justify-content:center;
        align-items:center;
    align-text:center;
    align-content:center;
    margin-top:38px;
    padding:16px;
        
    }
    `
