import React, {useState}from 'react';
import SideBar from '../components/SideBar';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import SectionA from '../components/SectionA';
import { homeObjA } from '../components/SectionA/data';
import { homeObjZ } from '../components/SectionB/data';
import SectionB from '../components/SectionB';
import Categories from '../components/Categories';
import { homeObjY } from '../components/Categories/data';
import ProductionSection from '../components/ProductionSection';
import { homeObjX } from '../components/ProductionSection/data';
import Marquee from 'react-fast-marquee';
import { MarqueeText, MarqueeWrapper } from '../components/MarqueeComponent/MarqueeElements';
import Gallery from '../components/Gallery/index.tsx';
import HeroImageCard from '../components/HeroImage';
import { SliderData } from '../components/HeroImage/data';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };

    return (
        <div className='homeWrapper'>
        <SideBar  isOpen={isOpen} toggle={toggle}/>
        <NavBar toggle={toggle}/>
        
        {/* <MarqueeWrapper>
        <Marquee speed='80' gradient={false}>
        <MarqueeText className='text18'>NUR Screen 1800 - 2,234 USD/MT</MarqueeText>
        <MarqueeText className='text17'>NUR Screen 1700+ - 2,184 USD/MT</MarqueeText>
        <MarqueeText className='text15'>NUR Screen 1500 - 2,134 USD/MT</MarqueeText>
        <MarqueeText className='text12'>NUR Screen 1200 - 2,084 USD/MT</MarqueeText>
      </Marquee>
      </MarqueeWrapper> */}


        <HeroImageCard slides={SliderData}  />
       <SectionA {...homeObjA}/>
       <Categories {...homeObjY} />
       <SectionB {...homeObjZ}/>
       <ProductionSection {...homeObjX}/>
       <Gallery/>
       
        <Footer/>
            
        </div>
    )
}

export default Home
