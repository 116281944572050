//data for about zicofe section
export const SectionAinfo = {
    id:'about',
    title:'38 Years Ago, We started',
    headline:'farming and processing coffee. Over the years, ZICOFE positioned itself as a coffee exporter',
    text:' We were among the first to build our own primary processing plant. We recently revamped our Our machine capacity is 60 tonnes a month, which produces green beans products packed in 60 kg gunny bags.',
    text1:'We mainly supply products such as natural Robusta sc1800,1500,1200 and mostly recently 1700. We also provide Washed Arabica AA, AB etc',
    imgStart:false,
    img:require('../../Images/coffee-picking.jpg'),
    alt:'About ZICOFE',
};

export const SectionAinfoB = {
    id:'about',
    title:'Women leading the way',
    headline:'At the forefront leading Zigoti Coffee (ZICOFE) is Rose Kato, the legendary woman who is at the forefront of driving domestic coffee consumption in Uganda. Rose is one of the proprietor of the Zicofe Coffee brand.',
    text:' We believe women have a big role to play in coffee value chain and as so should be valued as such,.',
    text1:'We encourage and specifically buy coffee from the women in co-operatives etc',
    imgStart:false,
    img:' https://d2kmvpaplrh563.cloudfront.net/zicofe-photos/wic.jpeg',
    alt:'About ZICOFE-WOMEN IN COFFEE',
};


//reviews title

export const ReviewsInfo = {
    id:'review-section-title',
    title:'A few words about us',
    text:'',
};
