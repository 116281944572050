import React from 'react';
import ReactDOM from 'react-dom';
//import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css' 
import { Card, Col, Row } from 'antd';
import { AwardsContainer } from './AwardsElements';
import {Typography } from "antd";

const { Title , Text } = Typography;

const { Meta } = Card;

const Awards = ({img,img2,img3,img4,title}) => {
  return (
    <AwardsContainer className="site-card-wrapper">

<Title level={3} style={{fontSize:"1.75em",color:"#35241A",lineHeight:"1.10722",fontWeight:"400",letterSpacing:"-.005em",marginTop:"6px",fontFamily:"Lato",marginBottom:"48px"}}> {title} </Title>
    <Row gutter={32}>
      <Col span={8}>
        <Card title="" bordered={false} hoverable
    style={{ width: 240 }}
    cover={<img alt="example" src={img} />}
  >
          {/* <Meta title="UGANDA COFFEE FEDERATION" /> */}
        </Card>
      </Col>

      <Col span={8}>
      <Card title="" bordered={false} hoverable
    style={{ width: 240 }}
    cover={<img alt="example" src={img2} />}
  >
          {/* <Meta title="UGANDA COFFEE FEDERATION" /> */}
        </Card>
      </Col>

      <Col span={8}>
      <Card title="" bordered={false} hoverable
    style={{ width: 240 }}
    cover={<img alt="example" src={img3} />}
  >
          {/* <Meta title="UGANDA COFFEE FEDERATION" /> */}
        </Card>
      </Col>

      \

    </Row>
  </AwardsContainer>
    );
}

export default Awards