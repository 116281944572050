import React, {useState} from 'react';
import { GridWrapper,GridItem , SectionText,SectionTextB,BtnWrap,ImgWrap,Img} from './categoriesElements';
import {Button ,Typography } from "antd";
//import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css' 
import { RightOutlined } from '@ant-design/icons';

const { Title , Text } = Typography;


const Categories = ({title,title2,headline,headline2,description,img,img2,alt}) => {
    const [hover,setHover] = useState(false);

    const onHover = () => {
        setHover(!hover)
    };

    return (

        


        <GridWrapper >

    
        <GridItem className='gridOne' >
        <SectionText className='gridA'>
                    
        <Title level={3} style={{fontSize:"1.75em",lineHeight:"1.10722",fontWeight:"400",letterSpacing:"-.005em",marginTop:"6px",fontFamily:"Lato"}}> {title} </Title>
                     
                     <Text level={5} style={{color:"#35241A", fontSize:"16px",lineHeight:"1.2381",fontWeight:"200",letterSpacing:".016em",marginTop:"15px",marginBottom:"6px",fontFamily:"Roboto"}}>{headline}</Text>
                     
                     <BtnWrap>
                     <Button href='/GreenBeans' type="link" size="medium"  style={{color:"#008000",
                       borderRadius:"6px",minWidth:"100px",marginTop:"6px"  }}>Learn More{ <RightOutlined />} </Button>
                     </BtnWrap>

                     <ImgWrap >
                         <Img src={img} alt={alt}/>
                     </ImgWrap>

                 </SectionText>

                 
        </GridItem>


        <GridItem className='gridTwo'>
        <SectionTextB>
                    
        <Title level={3} style={{fontSize:"1.75em",lineHeight:"1.10722",fontWeight:"400",letterSpacing:"-.005em",marginTop:"6px",fontFamily:"Lato"}}> {title2} </Title>
                     
                     <Text level={5} style={{color:"#35241A", fontSize:"16px",lineHeight:"1.2381",fontWeight:"200",letterSpacing:".016em",marginTop:"15px",marginBottom:"6px",fontFamily:"Roboto"}}>{headline2}</Text>
                     
                     <BtnWrap>
                     <Button href='/RoastedBeans' type="link" size="medium"  style={{color:"#008000",onHover:"#000",hover:"#008000",
                       borderRadius:"6px",minWidth:"100px",marginTop:"6px"  }}> Learn More { <RightOutlined />} </Button>
                     </BtnWrap>

                     <ImgWrap >
                         <Img src={img2} alt={alt}/>
                     </ImgWrap>
                 </SectionTextB>

                 
        
        </GridItem>
        
        </GridWrapper>

    
    )
}

export default Categories
