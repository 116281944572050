export const homeObjY = {
    id:'about',
    title:'Green Cofffee Beans',
    title2:'Roasted Coffee Beans',
    headline:'Ugandan Robusta has a rich aroma, a full body with winey flavours and is just the perfect finish for espresso blends. The natural Arabica, Drugar, is described as sweet with fruity notes and has a low acidity, whereas the washed Arabica has a rich full body with a good aroma  ',
    headline2:'Ugandan Coffee is a unique African coffee in the fact that it has a low acidity. This coffee is mild and smooth, with pleasant chocolate/tobacco/cedar notes, low to medium body. Exhibits flavor nuances of brown sugar and dried fruit, with a cocoa aroma.',
    imgStart:false,
    img:require('../../Images/greenBeans.jpg'),
    img2:require('../../Images/icon1.jpg'),
    alt:'Car',
};

