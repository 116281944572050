import React from 'react'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';


import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

import { GalleryContainer, GalleryTitle, GalleryItem } from './galleryElements';

import {Typography, Divider } from "antd";
//import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css' 

const { Title } = Typography;

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function Gallery() {
  return (
    <GalleryItem>

      <GalleryTitle>
      <Divider >
      <Title level={3} style={{fontSize:"35px",color:"#35241A",lineHeight:"1.10722",fontWeight:"600",letterSpacing:"-.005em",marginTop:"6px",fontFamily:"Lato"}}>
         Zigoti Coffee Activities
         </Title>
      {/* Right Text with 50px orientationMargin */}
    </Divider>
      </GalleryTitle>

      {/* <Divider >
      <Title level={3} style={{fontSize:"1.75em",color:"#35241A",lineHeight:"1.10722",fontWeight:"600",letterSpacing:"-.005em",marginTop:"6px",fontFamily:"Lato"}}>
         Zigoti Coffee Activities
         </Title>
    </Divider> */}

    <GalleryContainer>
    <ImageList
      // sx={{ width: 500, height: 450 }}
      cols={6} rowHeight={164}
      variant="woven"
      // variant="quilted"
      // cols={4}
      // rowHeight={121}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
          />
          {/* <ImageListItemBar position="top" 
          title={item.title}  
          actionIcon={
            <IconButton
              sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
              aria-label={`info about ${item.title}`}
            >
              <InfoIcon />
            </IconButton>
          }
          /> */}
        </ImageListItem>
      ))}
    </ImageList>
    </GalleryContainer>
    </GalleryItem>
  );
}

const itemData = [
  {
    img: 'https://d322abd807wvow.cloudfront.net/zicofe-photos/coffeePicking.jpg',
    title: 'Coffee Picking',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://d322abd807wvow.cloudfront.net/zicofe-photos/ZICOFE52Optimized.jpg',
    title: 'Zigoti Coffee Works roasted coffee display',
    cols: 2,
  },
  {
    img: 'https://d322abd807wvow.cloudfront.net/zicofe-photos/factory1Optimized.jpg',
    title: 'Zigoti Coffee Works primary processing plant',
    cols:2,

  },

  
  {
    img: 'https://d322abd807wvow.cloudfront.net/zicofe-photos/expo1.jpeg',
    title: 'Zigoti Coffee Works booth at trade show',
    cols:2,
  },
  {
    img: 'https://d322abd807wvow.cloudfront.net/zicofe-photos/zigotiCoffeeAwards.JPG',
    title: 'Zigoti Coffee Works Award winning coffee ',
    cols: 2,
  },
  {
    img: 'https://d322abd807wvow.cloudfront.net/zicofe-photos/zicofeFactory.jpeg',
    title: 'Zigoti Coffee Works Primary Processing plant',
    // author: '@arwinneil',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://d322abd807wvow.cloudfront.net/zicofe-photos/ctrlPanelForHullingSectionOptimized.jpg',
    title: 'Zigoti Coffee Works Primary Processing plant control panel for hullingn section',
  },
  {
    img: 'https://d322abd807wvow.cloudfront.net/zicofe-photos/zicofe-products.jpg',
    title: 'Zigoti Coffee Works Primary Processing plant roasted coffee products',
  },
  {
    img: 'https://d322abd807wvow.cloudfront.net/zicofe-photos/zicofecafe.jpeg',
    title: 'Zigoti Coffee Works CoffeeShop in Ntinda (ZICOFE Cafe)',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://d322abd807wvow.cloudfront.net/zicofe-photos/zigotiFarms.jpg',
    title: 'Zigoti Coffee Works farms',
    rows:2,
    cols:2,
  },
  {
    img: 'https://d322abd807wvow.cloudfront.net/zicofe-photos/zigotiFactorySide.jpg',
    title: 'Coffee',
  },
  {
    img: 'https://d322abd807wvow.cloudfront.net/zicofe-photos/factory2optimized.jpg',
    title: 'Zigoti Compound',
    cols: 2,
  },
];
