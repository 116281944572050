import styled from 'styled-components';
import { IoMdArrowRoundForward } from 'react-icons/io';
import {IoArrowForward, IoArrowBack} from 'react-icons/io5';


export const HeroSection = styled.section`
 height:90vh;
 max-height:1100px;
 position:relative;
 overflow:hidden;
 padding-top:10px;
 
 
`

export const HeroWrapper = styled.div`
 width:100%;
 height:100%;
 display:flex;
 justify-content:center;
 align-items:center;
 overflow:hidden;
 position:relative;

`

export const HeroSlide = styled.div`
z-index:1;
width:100%;
height:100%;
`
export const HeroSlider = styled.div`
position:Absolute;
top:0;
left:0;
width:100%;
height:100%;
display:flex;
align-items:center;
justify-content:center;

&::before {
    content:''; x
    position:Absolute;
    z-index:2;
    width:100%;
    height:100vh;
    bottom:0vh;
    left:0;
    overflow:hidden;
    opacity:0.4;
    background:linear-gradient(0deg, rgba(0,0,0,0.2) 0%,
     rgba(0,0,0,0.2) 50%,
     rgba(0,0,0,0.6) 100%,
     )

}
`
export const HeroImage = styled.img`
position:Absolute;
top:0;
left:0;
width:100vw;
height:100vh;
object-fit:cover;
`

export const HeroContent = styled.div`
position:relative;
z-index:10;
display:flex;
flex-direction:column;
margin: auto;
width: 50%;
padding: 10px;
max-width:1600px;
${'' /* width:calc(100% - 100px); */}
color:#fff;
right:50px;

/* From https://css.glass */
background: rgba(255, 255, 255, 0.17);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(8.2px);
-webkit-backdrop-filter: blur(8.2px);
border: 1px solid rgba(255, 255, 255, 0.27);

h1 {
    font-size:clamp(1rem, 8vw, 2rem);
    font-weight:400;
    ${'' /* text-transform:uppercase; */}
    text-shadow:0px 0px 20px rgba(0,0,0,0.4);
    text-align:left;
    ${'' /* margin-bottom:0.8rem; */}
    margin-bottom:0.8rem 0px 0.8rem 0px;
    color:#fff;
    
}

p {
    margin-bottom:1.2rem;
    text-shadow:0px 0px 20px rgba(0,0,0,0.4);
    
}

.ant-btn {
    margin:24px;
}

${'' /* @media screen and (max-width: 968px) {
        margin:24px;
        padding:24px;

        h1 {
            width:100vw;
        }
    } */}
`

export const SliderButtons = styled.div`
position:Absolute;
bottom:50px;
right:50px;
display:flex;
z-index:10;

`
export const Arrow = styled(IoMdArrowRoundForward)`

${'' /* padding-left:1vw;
padding-right:1vw; */}
`;

// const arrowButtons = css`
// width:50px;
// height:50px;
// color:#fff;
// cursor:pointer;
// background:#000dla;
// border-radius:50px;
// padding:10px;
// margin-right:1rem;
// user-select:none;
// transition:0.3s;

// &:hover {
//     background: #cd853f;
//     transform:scale(1.05);

// }
// `;

export const PrevArrow = styled(IoArrowBack)`
width:50px;
height:50px;
color:#fff;
cursor:pointer;
${'' /* background:#000; */}
border-radius:50px;
padding:10px;
margin-right:1rem;
user-select:none;
transition:0.3s;
margin-left:0.5rem;
/* From https://css.glass */
${'' /* background: rgba(255, 255, 255, 0.17); */}
background: rgba(0, 0, 0, 0.7);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(8.2px);
-webkit-backdrop-filter: blur(8.2px);
border: 1px solid rgba(255, 255, 255, 0.27);

&:hover {
    background: #cd853f;
    transform:scale(1.05);

}
`

export const NextArrow = styled(IoArrowForward)`
width:50px;
height:50px;
color:#fff;
cursor:pointer;
${'' /* background:#000; */}
border-radius:50px;
padding:10px;
margin-right:1rem;
user-select:none;
transition:0.3s;
margin-left:0.5rem;
/* From https://css.glass */
${'' /* background: rgba(26, 16, 6, 0.17); */}
background: rgba(0, 0, 0, 0.7);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(8.2px);
-webkit-backdrop-filter: blur(8.2px);
border: 1px solid rgba(26, 16, 6, 0.27);

&:hover {
    background: #cd853f;
    transform:scale(1.05);

}
`


