import React from 'react';
import {Typography,Card} from "antd";
//import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css'  
import {ProductCategory} from "./ProductCardElements";
import pic from './greenBeans.jpg';


const { Title , Text } = Typography;
const { Meta } = Card;

const ArabicaCard = ({title,title1,title2,price,price1,price2,img,headline,description1,description2}) => {
  return <ProductCategory id='productCategory'>

    
  <Card
    hoverable
    style={{ width: 240 , height:460, margin:16}}
    cover={<img alt="example" src={img} />}
  >
    <p><Meta title={title1} description={description1} /></p>
    {/* <p>{price1}</p> */}
  </Card>


  <Card
    hoverable
    style={{ width: 240 , height:460}}
    cover={<img alt="example" src={img} />}
  >
    <p><Meta title={title2} description={description2} /></p>
    {/* <p>{price2}</p> */}
  </Card>


  </ProductCategory>;
};

export default ArabicaCard;
