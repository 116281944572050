import React from 'react';
import {FooterContainer,FooterWrap,FooterLinksContainer,FooterLinksWrapper,FooterLinkItems,FooterLinkTitle,FooterLink,SocialMedia,SocialMediaWrap,SocialLogo,SocialIcons,SocialIconLink,WebsiteRights,Img, LocationOnMap} from './FooterElements';
import {FaFacebook,FaInstagram,FaWhatsapp,FaTwitter,FaTiktok} from 'react-icons/fa';
import {BackTop} from 'antd';
//import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css'   
import { Button, Divider } from 'antd';
import logo from '../../Images/zicofeLogoB.png';
import GoogleMaps from '../Map';

const location = {
    address: 'Zigoti Coffee Works Ltd',
    lat: 0.31952388690630096,
    lng: 32.612368559943604,
  } // our location object from earlier


const Footer = () => {
    return (
        <FooterContainer>

        <FooterWrap id='footerWrap'>
       

            <FooterLinksContainer id='footerLinksContainer'>
                <FooterLinksWrapper>

                 <FooterLinkItems>

                     <FooterLinkTitle>About ZICOFE</FooterLinkTitle>
   
                     <FooterLink to="/About">History</FooterLink>
                     <FooterLink to="/About">ZICOFE in the community</FooterLink>
                     <FooterLink to="/About">Testimonials</FooterLink>
                     <FooterLink to="/About">Gallery</FooterLink>
                     

                 </FooterLinkItems>

                 <FooterLinkItems>

                     <FooterLinkTitle>What we do</FooterLinkTitle>
   
                     <FooterLink to="/About">Coffee Buying</FooterLink>
                     <FooterLink to="/About">Coffee Processing</FooterLink>
                     <FooterLink to="/About">Coffee Roasting</FooterLink>
                     <FooterLink to="/About">Coffee Export</FooterLink>
                     

                 </FooterLinkItems>

                 <FooterLinkItems>

                     <FooterLinkTitle>Robusta</FooterLinkTitle>
   
                     <FooterLink to="/GreenBeans">Screen 12</FooterLink>
                     <FooterLink to="/GreenBeans">Screen 15</FooterLink>
                     <FooterLink to="/GreenBeans">Screen 18</FooterLink>
                     <FooterLink to="/GreenBeans">BHP </FooterLink>
                     
                 </FooterLinkItems>


                 {/* <FooterLinkItems> */}

                     {/* <FooterLinkTitle>Production</FooterLinkTitle> */}
   
                     {/* <FooterLink to="/About">Wet processing</FooterLink>
                     <FooterLink to="/About">Dry processing</FooterLink>
                     <FooterLink to="/About">Roastery</FooterLink>
                     <FooterLink to="/About"></FooterLink> */}
                     {/* <Button type="link" href='http://www.zicofecafe.com' style={{color:'#35241A'}}>Zicofe Cafe</Button> */}
                     {/* <Link> <a href='http://www.zicofecafe.com'>ZICOFE Cafe</a></Link> */}
                     

                 {/* </FooterLinkItems> */}


                 <FooterLinkItems>

                     <FooterLinkTitle>Reach Us</FooterLinkTitle>
   
                     <FooterLink to="/ContactUs">Visit our Factory</FooterLink>
                     <FooterLink to="/About">Visit our Roastery</FooterLink>
                     <FooterLink to="/About">Visit our Cafe</FooterLink>
                     <FooterLink to="/ContactUs"> Contact Us
                     
                     </FooterLink>
                     


                 </FooterLinkItems>
                 

                 

                </FooterLinksWrapper>

            </FooterLinksContainer>

            {/* <LocationOnMap>
            <GoogleMaps location={location} zoomLevel={17} />
            </LocationOnMap> */}

            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to="/">  
                    <Img src={logo} alt='ZICOFE LOGO'/>
                    </SocialLogo>
                    <WebsiteRights>ZICOFE © {new Date().getFullYear()}
                    All rights reserved.</WebsiteRights>
                    <SocialIcons>
                        <SocialIconLink href="https://www.facebook.com/ZigotiCoffee" target="_blank" arial-label="Facebook">
                            <FaFacebook/>
                        </SocialIconLink>
                        <SocialIconLink href="https://www.instagram.com/zigoticoffeeworks/" target="_blank" arial-label="Instagram">
                            <FaInstagram/>
                        </SocialIconLink>
                        <SocialIconLink href="https://twitter.com/ZicofeOfficial" target="_blank" arial-label="Twitter">
                            <FaTwitter/>
                        </SocialIconLink>
                        <SocialIconLink href="https://www.tiktok.com/@zigoticoffee" target="_blank" arial-label="Tiktok">
                            <FaTiktok/>
                        </SocialIconLink>
                        <SocialIconLink href="https://wa.me/message/FTZ2CS3TCD4HC1" target="_blank" aria-label="Whatsapp">
                            <FaWhatsapp/>
                        </SocialIconLink>
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>

        
            

        </FooterWrap>
        <BackTop/>
            
        </FooterContainer>
    )
}

export default Footer