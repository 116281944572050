import React, { Component } from 'react';
import { BrowserRouter as Router,Routes, Route} from 'react-router-dom';
import Home from './pages/index';
import About from './pages/About';
import GreenBeans from './pages/GreenBeans';
import RoastedBeans from './pages/RoastedBeans';
import Shop from './pages/Shop';
import './App.css';
import ReactGA from 'react-ga';
import ContactUs from './pages/ContactUs';
import CookieConsent from "react-cookie-consent";

ReactGA.initialize('UA-218201417-2');
ReactGA.pageview(window.location.pathname + window.location.search);


class App extends Component {
render() {
	return (
	<Router>

	<CookieConsent
	location='bottom' 
   buttonText='Ok!' 
   cookieName='<cookieName>' 
   style={{ background: "#35241A" }} 
   buttonStyle={{ color: '#F7F2EF', fontSize: '13px' }}    
   expires={150}
	>
	This website uses cookies to enhance the user experience.
	<span style={{fontSize:'10px'}}></span>
	</CookieConsent>
		
		<Routes>
				<Route exact path='/' element={< Home />}></Route>
				<Route exact path='/about' element={< About />}></Route>
				<Route exact path='/greenBeans' element={< GreenBeans />}></Route>
                <Route exact path='/roastedBeans' element={< RoastedBeans />}></Route>
                <Route exact path='/shop' element={< Shop />}></Route>
                <Route exact path='/signin' element={< signin />}></Route>
                <Route exact path='/contactUs' element={< ContactUs/>}></Route>

		</Routes>
	
	</Router>
);
}
}

export default App;
