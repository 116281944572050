import styled from 'styled-components';

export const ProductCategory = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    ${'' /* position:sticky; */}
    top:0;
    background-color: #F7F2EF;
    cursor:pointer;
    font-family:"Lato","Roboto","Helvetica Neue",sans-serif;
    
    @media screen and (max-width: 768px) {
        display:flex;
        flex-direction:column;
        padding-bottom:16px;
    `
export const ProductName = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    ${'' /* position:sticky; */}
    top:0;
    background-color: #F7F2EF;
    cursor:pointer;
    font-family:"Lato","Roboto","Helvetica Neue",sans-serif;
    flex-basis:100%;
    width:1200px;
    @media screen and (max-width: 768px) {
        display:flex;
        flex-direction:column;
        padding-bottom:16px;
    `               

 /* export const ProductName = styled.div`
    justify-content:center;
    align-items:center;
    display:flex;
    flex-direction:column;
    width:100%;
    margin:80px;
    ` */

    export const Img = styled.img`
    margin:auto;
   width:100%;
   height:400px;
   object-fit:contain;
   left:0;
   
 display:flex; 
 flex-wrap:wrap;
 
 @media screen and (max-width:720px) {
     flex-direction:column;
     align-items:center;

 }
`