import React from 'react';
import { FaBars } from 'react-icons/fa';
import {Nav,NavBarContainer,NavLogo,MobileIcon,NavMenu,NavItem,NavLinks,NavBtn,NavBtnLink,Img} from './NavBarElements';
import logo from '../../Images/zicofeLogoRPB96.png';
// import zcwlogo from '../../Images/zcwlogo.png';
//import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css'  


//import { Typography } from 'antd';

//const { Title } = Typography;

const NavBar = ({toggle,img,alt}) => {
    return (
        <>
          <Nav>
              <NavBarContainer>
                  <NavLogo to='/'>
                  {/* ZICOFE */}
                      <Img src={logo} alt={alt}/>
                  </NavLogo>

                  <MobileIcon onClick={toggle}>
                      <FaBars/>
                  </MobileIcon>

                  <NavMenu>
                      <NavItem>
                          <NavLinks to="/About ">About ZICOFE</NavLinks>
                      </NavItem>
                      
                      <NavItem>
                          <NavLinks to="/GreenBeans">Green Beans</NavLinks> 
                      </NavItem>

                      <NavItem>
                          <NavLinks to="/RoastedBeans">Roasted Beans</NavLinks> 
                      </NavItem>

                      <NavItem>
                          {/* <NavLinks to="/Roastery">Roastery</NavLinks> */}
                          <NavLinks to="/ContactUs">Roastery</NavLinks>
                      </NavItem>

                     


                      <NavItem>
                          <NavLinks to="/ContactUs">Shop</NavLinks>
                          {/* <NavLinks to="/shop">Shop</NavLinks> */}
                      </NavItem>


                      

                  </NavMenu>

                  <NavBtn>
                      <NavBtnLink to="/ContactUs"> Contact us </NavBtnLink>
                  </NavBtn>
                  
              </NavBarContainer>
          </Nav>
            
        </>
    )
}

export default NavBar