import React from 'react';
import { Divider } from 'antd';
import ProductHeading from '../ProductCategory/ProductHeading';
import ProductTitle from '../ProductCategory/ProductTitle';
import ProductCard from '../ProductCategory/ProductCard';
import ProductSubTitle from '../ProductCategory/ProductSubTitle';
import ArabicaCard from '../ProductCategory/ProductCard/ArabicaWashed';
import { homeObjV } from '../ProductCategory/ProductHeading/data';
import { homeObjR, homeObjZ } from '../ProductCategory/ProductTitle/data';
import { homeObjD, homeObjE, homeObjF } from '../ProductCategory/ProductCard/data';
import { homeObjH, homeObjI } from '../ProductCategory/ProductSubTitle/data';

const RoastedBeans = () => {
  return <div>
  <ProductHeading  {...homeObjV}/>
  <ProductTitle {...homeObjZ}/>
  <ProductCard {...homeObjD} />
  {/* <ProductTitle {...homeObjR} />
  <ProductSubTitle  {...homeObjH}/>
  <ProductCard  {...homeObjE}/>
  <ProductSubTitle {...homeObjI}/>
  <ArabicaCard  {...homeObjF}/> */}
  <Divider/>


  </div>;
};

export default RoastedBeans;
