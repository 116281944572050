import styled from 'styled-components';
import {Link} from 'react-scroll';


export const HeroContainer = styled.div`
 display:flex;
 flex-direction:column;
 justify-content:center;
 align-items:center;
 ${'' /* background:#F7F2EF; */}
 z-index:1;


`

export const HeroWrapper = styled.div`
 background:inherit;
 padding-bottom:56px;
 ${'' /* padding-top:10px; */}
 display:flex;
 width:100%;
`




export const Button = styled(Link)`
 color:#2c8746;
 display: flex;
 justify-content:center;
 align-items:center;
 font-style: normal;
 z-index: 1;
 text-align:center;
 font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
 font-weight:400;
 margin-top:.4em;
 font-size: 17px;
 line-height: 1.381;
`





//maize content for images section

export const HeroContent = styled.div`
 display:flex; 
 flex-wrap:wrap;
 height:100%;
 width:100%;
 padding:16px;
 margin:auto;
 justify-content:space-around;
 align-items:center;
 margin-bottom:-24px;
 ${'' /* margin-top:24px; */}
 text-align:center;
 align-content:stretch;
 
 
 
 @media screen and (max-width:720px) {
     flex-direction:column;
     align-items:center;

 }

`





