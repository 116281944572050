import React, {useState} from 'react';
import { GridWrapper,GridItem , SectionText,BtnWrap,ImgWrap,Img} from './sectionElements';
import {Button ,Typography } from "antd";
//import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css' 
import { RightOutlined } from '@ant-design/icons';

const { Title , Text } = Typography;


const SectionA = ({title,headline,headline1,headline2,description,img,alt}) => {
    const [hover,setHover] = useState(false);

    const onHover = () => {
        setHover(!hover)
    };

    return (

        


        <GridWrapper >

    
        <GridItem >
        <SectionText>
                    
        <Title level={3} style={{fontSize:"1.75em",lineHeight:"1.10722",fontWeight:"400",letterSpacing:"-.005em",marginTop:"6px",fontFamily:"Lato"}}> {title} </Title>
                     
                     <Text level={5} style={{color:"#35241A", fontSize:"16px",lineHeight:"1.2381",fontWeight:"200",letterSpacing:".016em",marginTop:"15px",marginBottom:"15px",fontFamily:"Roboto"}}>{headline} 
                     </Text>
                     <Text level={5} style={{color:"#35241A", fontSize:"16px",lineHeight:"1.2381",fontWeight:"200",letterSpacing:".016em",marginTop:"15px",marginBottom:"15px",fontFamily:"Roboto"}}>{headline1}
                     </Text>
                     <Text level={5} style={{color:"#35241A", fontSize:"16px",lineHeight:"1.2381",fontWeight:"200",letterSpacing:".016em",marginTop:"15px",marginBottom:"15px",fontFamily:"Roboto"}}>{headline2}
                     </Text>
                     
                     <BtnWrap>
                     <Button href='/ContactUs' type="link" size="medium"  style={{color:"#008000",background:"#F7F2EF",
                       borderRadius:"6px",minWidth:"100px",marginTop:"15px"  }}>Learn More{ <RightOutlined />} </Button>
                     </BtnWrap>
                 </SectionText>
        
        </GridItem>
        


        <GridItem>

        <ImgWrap >
                         <Img src={img} alt={alt}/>
                     </ImgWrap>
        </GridItem>
        
        
        </GridWrapper>

    
    )
}

export default SectionA
