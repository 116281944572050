import React from 'react';
import {Typography} from "antd";
//import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css' 
import {ProductCategory,ProductName} from "./reviewElements";

const { Title , Text } = Typography;

const SubTitle = ({title,text1}) => {
  return <ProductCategory id='productCategory'>
      <ProductName id='productName'> 
      <Title level={3} style={{fontSize:"1.75em",lineHeight:"1.10722",fontWeight:"400",letterSpacing:".004em",marginTop:"6px",marginBottom:"28px",fontFamily:"Lato"}}> 
      {title} 
      </Title>
       </ProductName>
  </ProductCategory>;
};

export default SubTitle;
