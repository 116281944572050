
import React , {useState, useRef} from 'react';
import {HeroSection,HeroWrapper, HeroSlide, HeroImage, HeroContent, HeroSlider, SliderButtons, Arrow, PrevArrow, NextArrow} from './HeroImageElements';
import {Button ,Typography } from "antd";
import { LeftOutlined, RightOutlined,ArrowRightOutlined } from '@ant-design/icons';
//import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css' 
import { useEffect } from 'react';





const { Title } = Typography;


const HeroImageCard = ({title,headline,buttonText,video,slides}) => {
    const [current,setCurrent] = useState(0);
    const length = slides.length;
    const timeout = useRef(null);

    useEffect(() => {
        const nextSlide = () => {
            setCurrent(current => (current === length - 1 ? 0 : current + 1))
        }

        timeout.current = setTimeout(nextSlide, 3000)

        return function () {
            if(timeout.current) {
                clearTimeout(timeout.current)
            }
        }
    }, [current,length])


    const nextSlide = () => {
        if(timeout.current) {
            clearTimeout(timeout.current)
        }
        setCurrent(current === length - 1 ? 0 : current + 1);
        // console.log(current);
    };

    const prevSlide = () => {
        if(timeout.current) {
            clearTimeout(timeout.current)
        }

        setCurrent(current === 0 ? length - 1 : current - 1);
        // console.log(current);

    };

    if(!Array.isArray(slides) || slides.length <= 0) {
        return null
    }

    return (
        <HeroSection id="heroSection">

        <HeroWrapper id="heroWrapper">

        {slides.map((slide, index) => {
            return (
                <HeroSlide key={index}>
                {index === current && (
                    <HeroSlider>
                    <HeroImage src={slide.image} alt={slide.alt} id="heroImage"/>
                    <HeroContent>
                        <h1>{slide.title}</h1>
                        <p>{slide.caption}</p>
                        <Button to={slide.path} primary="true"
                        css={`background: rgba(0, 0, 0, 0.7);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(8.2px);
-webkit-backdrop-filter: blur(8.2px);
border: 1px solid rgba(26, 16, 6, 0.27);`
                            // `max-width:240px;/* From https://css.glass */
                            //   width:150px;
                            //   background: rgba(255, 255, 255, 0.17);
                            //   border-radius: 16px;
                            //   box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                            //   backdrop-filter: blur(8.2px);
                            //   -webkit-backdrop-filter: blur(8.2px);
                            //   border: 1px solid rgba(255, 255, 255, 0.27);
                            //   padding:16px;`
                              }>
                            {slide.buttonText}
                            <ArrowRightOutlined />
                            {/* <Arrow/> */}
                        </Button>
                        
                    </HeroContent>
                </HeroSlider>

                )}
                

                </HeroSlide>

            )
        })}
    
        <SliderButtons>
        {/* <LeftOutlined />
        <RightOutlined /> */}
        <PrevArrow onClick={prevSlide}/>
        <NextArrow onClick={nextSlide}/>
        </SliderButtons>

    
        </HeroWrapper>

        </HeroSection>

    )
}


export default HeroImageCard