export const homeObjA = {
    id:'about',
    title:'Contacting Zicofe',
    title1:'Sales And Product Inquiries',
    title2:'Cooporate Address',
    title3:'Roasting Inquiries',
    text:'Zicofe.com is a convenient place to purchase Zicofe coffee.',
    text2:'You can buy online or call + (256) 762 764 298.',
    text3:'We welcome any questions you may have about our products and services.',
    phone:'+ (256) 762 764 298',
    email:'sales@zicofe.com',
    email1:'suwik@zicofe.com',
    email2:"sales@zicofe.com",
    email3:'You can reach us at sales@zicofe.com',
};

