import styled from "styled-components"

export const CarouselContainer = styled.div`
    
    object-fit:contain;

    @media screen and (max-width:780px) {
        margin:16px 0 16px 0;
        padding:32px;
        object-fit:contain;
        
    }

    .ant-carousel .slick-slide img {
        width:85.375em;
        justify-content:center;
        align-items:center;
        object-fit:contain;
    }
        
`

export const ImgWrap = styled.div`

@media screen and (max-width:780px) {
    display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
    

    img{
        display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;

    }

}
`

