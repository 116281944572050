export const homeObjF = {
    id:'ProductSubTitle',
    title:'Washed Arabica Coffee ',
};

export const homeObjG = {
    id:'ProductSubTitle',
    title:'Natural Arabica Coffee ',
};

export const homeObjH = {
    id:'ProductSubTitle',
    title:'Natural Arabica Coffee ',
};


export const homeObjI = {
    id:'ProductSubTitle',
    title:'Washed Arabica Coffee ',
};

export const homeObjT = {
    id:'ContactTitle',
    title:'Washed Arabica Coffee ',
};


export const homeObjX = {
    id:'ContactTitle',
    title:'',
};
